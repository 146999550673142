"use client";

import React from "react";
export const BusIcon: any = ({
  size = 40,
  width = 40,
  height = 40,
  color,
  ...props
}: {
  size?: number;
  width?: number;
  height?: number;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    viewBox="0 0 512 512"
    fill={color}
    {...props}
  >
    <path d="" />
  </svg>
);

export const BusIconUrl = (color: string) => {
  const svgString = new XMLSerializer().serializeToString(
    React.createElement(BusIcon, {color: "red"}) as unknown as Element
  );
  const blob = new Blob([svgString], {type: "image/svg+xml"});
  return URL.createObjectURL(blob);
};
