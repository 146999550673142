"use client";
import {Button, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoButton = extendVariants(Button, {
  variants: {
    color: {
      yellow: "text-neo-black bg-neo-yellow",
      black: "bg-neo-black text-neo-white dark:bg-neo-yellow dark:text-neo-black",
      white: "bg-neo-white text-neo-black border-neo-black-400 border-2",
      red: "bg-neo-red text-neo-white",
      success: "bg-neo-green text-neo-white",
      secondary: "bg-gray-100 text-neo-black"
    },
    isDisabled: {
      true: "bg-[#eaeaea] text-[#000] opacity-50 cursor-not-allowed"
    },
    size: {
      xs: "px-unit-2 min-w-unit-12 h-unit-6 text-tiny gap-unit-1 rounded-small",
      md: "px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 rounded-none",
      lg: "px-unit-7 min-w-unit-28 h-unit-12 text-small gap-unit-4 rounded-medium",
      xl: "px-unit-8 min-w-unit-28 h-unit-14 text-large gap-unit-4 rounded-medium"
    }
  },
  defaultVariants: {
    color: "black",
    size: "lg"
  },
  compoundVariants: [
    {
      isDisabled: true,
      color: "black",
      class: "bg-[#84cc16]/80 opacity-100"
    }
  ]
});
