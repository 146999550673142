"use client";
import {extendVariants, Select} from "@nextui-org/react";

// Responsive Reference:https://github.com/nextui-org/nextui/discussions/2047

export const NeoSelect = extendVariants(Select, {
  defaultVariants: {
    textSize: "base",
    variant: "bordered",
    labelPlacement: "outside",
    radius: "lg",
    size: "lg"
  }
});
