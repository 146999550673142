"use client";
import {Chip, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoChips = extendVariants(Chip, {
  variants: {
    variant: {
      dot: {
        base: "border-none text-black"
      }
    }
  },
  defaultVariants: {
    radius: "none",
    variant: "dot",
    size: "sm"
  }
});
