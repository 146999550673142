export const AlertIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="none"
    {...props}
  >
    <path
      fill="#FC2A3D"
      d="M70.663 38.486 57.605 51.539l13.058 13.053a3.969 3.969 0 0 1-1.287 6.47 3.966 3.966 0 0 1-4.323-.86L52 57.144 38.947 70.202a3.966 3.966 0 1 1-5.61-5.61L46.395 51.54 33.337 38.486a3.967 3.967 0 0 1 5.61-5.61L52 45.934l13.053-13.058a3.964 3.964 0 0 1 5.61 0 3.968 3.968 0 0 1 0 5.61Zm32.876 13.053a51.542 51.542 0 0 1-61.594 50.549A51.542 51.542 0 0 1 1.451 61.594 51.539 51.539 0 0 1 52 0a51.593 51.593 0 0 1 51.539 51.54Zm-7.929 0A43.61 43.61 0 1 0 52 95.15a43.66 43.66 0 0 0 43.61-43.61Z"
    />
  </svg>
);
