export const UploadIcon = ({
  size = 22,
  width = 22,
  height = 22,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M18.56 12.954v4.558a1.302 1.302 0 0 1-1.303 1.303H4.232a1.302 1.302 0 0 1-1.302-1.303v-4.558a.651.651 0 1 1 1.302 0v4.558h13.025v-4.558a.651.651 0 1 1 1.303 0ZM7.488 7.744h2.605v5.21a.652.652 0 0 0 1.303 0v-5.21H14a.651.651 0 0 0 .46-1.112l-3.256-3.256a.65.65 0 0 0-.921 0L7.028 6.631a.651.651 0 0 0 .46 1.112Z"
    />
  </svg>
);
