export const RoadIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M47 14.7c0 2.7-.3 6.5-.6 8.5l-.7 3.8h8.6l-.7-3.8c-.3-2-.6-5.8-.6-8.5 0-4.6-.1-4.7-3-4.7s-3 .1-3 4.7zM47 36.2c0 1.8-.3 5-.6 7l-.7 3.8h8.6l-.7-3.8c-.3-2-.6-5.2-.6-7 0-2.9-.3-3.2-3-3.2s-3 .3-3 3.2zM47 56.2c0 1.8-.3 5-.6 7l-.7 3.8h8.6l-.7-3.8c-.3-2-.6-5.2-.6-7 0-2.9-.3-3.2-3-3.2s-3 .3-3 3.2zM47 78.4c0 2.9-.3 6.8-.6 8.5-.6 3.1-.6 3.1 3.6 3.1s4.2 0 3.6-3.1c-.3-1.7-.6-5.6-.6-8.5 0-5.3 0-5.4-3-5.4s-3 .1-3 5.4z" />
  </svg>
);
