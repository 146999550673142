export const DownloadIcon = ({
  size = 22,
  width = 22,
  height = 22,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M6.864 10.159a.651.651 0 0 1 .46-1.112H9.93v-5.21a.651.651 0 0 1 1.302 0v5.21h2.605a.65.65 0 0 1 .46 1.112l-3.255 3.255a.65.65 0 0 1-.922 0L6.864 10.16Zm10.879 2.144a.651.651 0 0 0-.651.65v4.559H4.068v-4.558a.651.651 0 1 0-1.302 0v4.558a1.302 1.302 0 0 0 1.302 1.302h13.024a1.302 1.302 0 0 0 1.302-1.302v-4.558a.651.651 0 0 0-.651-.651Z"
    />
  </svg>
);
