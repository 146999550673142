export const VehiclesIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M21.0675 0.593964H2.93098C1.51767 0.593964 0.361328 1.83011 0.361328 3.34097V24.5434C0.361328 25.8942 1.28647 27.0225 2.49234 27.2471V28.2655C2.49234 29.5461 3.47252 30.594 4.67052 30.594C5.86852 30.594 6.84869 29.5461 6.84869 28.2655V27.2904H17.1499V28.2655C17.1499 29.5461 18.13 30.594 19.328 30.594C20.526 30.594 21.5062 29.5461 21.5062 28.2655V27.2471C22.7121 27.0225 23.6372 25.8942 23.6372 24.5434V3.34097C23.6372 1.83011 22.4809 0.593964 21.0675 0.593964ZM4.67052 23.9577C3.82844 23.9577 3.14579 23.228 3.14579 22.3278C3.14579 21.4276 3.82844 20.6978 4.67052 20.6978C5.5126 20.6978 6.19524 21.4276 6.19524 22.3278C6.19524 23.228 5.5126 23.9577 4.67052 23.9577ZM19.328 23.9577C18.4859 23.9577 17.8033 23.228 17.8033 22.3278C17.8033 21.4276 18.4859 20.6978 19.328 20.6978C20.1701 20.6978 20.8527 21.4276 20.8527 22.3278C20.8527 23.228 20.1701 23.9577 19.328 23.9577ZM21.5062 17.4961H2.49234V9.50647C2.49234 9.11407 2.78993 8.79594 3.157 8.79594H20.8415C21.2086 8.79594 21.5062 9.11407 21.5062 9.50647V17.4961ZM21.8827 4.73447C21.8827 5.75021 21.1053 6.58129 20.1551 6.58129H4.25525C3.30319 6.58129 2.52423 5.74857 2.52423 4.7308V4.67708C2.52423 3.65931 3.30319 2.82658 4.25525 2.82658H20.1551C21.1053 2.82658 21.8827 3.65765 21.8827 4.67341V4.73447Z" />
  </svg>
);
