"use client";
import {Label} from "flowbite-react";
import React, {useState} from "react";
import Hue from "react-color/lib/components/hue/Hue";

interface ColorPickerProps {
  label?: string;
  value?: string;
  errorMessage?: string;
  handleChange: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  value,
  errorMessage,
  handleChange
}) => {
  const [color, setColor] = useState<string>("#" + value || "#ffffff");
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const onChangeColor = (newColor: any) => {
    setColor(newColor?.hex);
    handleChange(newColor?.hex);
  };

  return (
    <div className="grid grid-cols-1">
      {label && (
        <Label
          className={`mb-1 block text-base font-normal`}
          value={`${label}`}
        />
      )}
      <div className="flex flex-row space-x-3">
        <div
          className="h-8 w-8 rounded border-3 border-gray-300 pr-2"
          onClick={() => setShowPicker(!showPicker)}
          style={{backgroundColor: color}}
        />
        {showPicker && <Hue color={color} onChange={e => onChangeColor(e)} />}
      </div>
      {errorMessage && <span className="text-red-700">{errorMessage}</span>}
    </div>
  );
};
