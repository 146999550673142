export const ReportsIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3525 5.57442V0.0944214H4.88543C2.64465 0.0944214 0.828125 1.93454 0.828125 4.20442V26.1244C0.828125 28.3944 2.64465 30.2344 4.88543 30.2344H21.1147C23.3555 30.2344 25.172 28.3944 25.172 26.1244V11.0544H19.7622C16.7746 11.0544 14.3525 8.60094 14.3525 5.57442ZM17.0574 5.57442V0.896954L24.3797 8.31442H19.7622C18.2683 8.31442 17.0574 7.08768 17.0574 5.57442ZM6.23787 8.31442H11.6476V5.57442H6.23787V8.31442ZM13.0001 12.4244H6.23787V9.68442H13.0001V12.4244ZM11.4123 15.8009C12.4629 14.7634 14.1384 14.7634 15.1888 15.8009L16.8418 17.4336C17.364 17.9492 17.6584 18.6567 17.6584 19.3954V22.0144C17.6584 23.5277 16.4474 24.7544 14.9535 24.7544H11.6476C10.1538 24.7544 8.94274 23.5277 8.94274 22.0144V19.3954C8.94274 18.6567 9.23719 17.9492 9.75934 17.4336L11.4123 15.8009Z"
    />
  </svg>
);
