import {BreadcrumbItem, Breadcrumbs} from "@nextui-org/react";

interface NeoBreadcrumbProps {
  menus: ShortMenu[];
}

interface ShortMenu {
  label: string;
  link: string;
}

export const NeoBreadcrumb: React.FC<NeoBreadcrumbProps> = ({menus}) => {
  return (
    <Breadcrumbs>
      {menus.map((item: any, key: any) => (
        <BreadcrumbItem key={key} href={item.link}>
          {item.label}
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
};
