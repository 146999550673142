export const UsersIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M14.9992 16.3853C19.3598 16.3853 22.8948 12.8503 22.8948 8.48961C22.8948 4.12896 19.3598 0.593964 14.9992 0.593964C10.6385 0.593964 7.10352 4.12896 7.10352 8.48961C7.10352 12.8503 10.6385 16.3853 14.9992 16.3853Z" />
    <path d="M29.935 27.7376C29.2861 24.7461 27.633 22.0668 25.2506 20.145C22.8681 18.2231 19.8996 17.1744 16.8386 17.1732H13.1614C10.1031 17.185 7.14003 18.2376 4.7597 20.1577C2.37936 22.0778 0.723625 24.7511 0.0649853 27.7376C-0.0245909 28.1445 -0.0215261 28.5663 0.0739526 28.9718C0.169431 29.3774 0.35488 29.7563 0.616577 30.0805C0.872275 30.3985 1.19633 30.6547 1.56466 30.8302C1.93299 31.0058 2.33613 31.0961 2.74415 31.0944C3.3597 31.0971 3.95113 30.8552 4.38842 30.422C5.53087 29.2554 6.89509 28.3292 8.4008 27.6977C9.90652 27.0661 11.5233 26.7421 13.1561 26.7447H16.8334C18.4562 26.7405 20.0637 27.0587 21.5626 27.681C23.0614 28.3032 24.4216 29.2171 25.5643 30.3694C25.7838 30.5968 26.0465 30.778 26.337 30.9025C26.6275 31.0269 26.9398 31.0922 27.2559 31.0944C27.6639 31.0961 28.067 31.0058 28.4353 30.8302C28.8037 30.6547 29.1277 30.3985 29.3834 30.0805C29.6451 29.7563 29.8306 29.3774 29.926 28.9718C30.0215 28.5663 30.0246 28.1445 29.935 27.7376Z" />
  </svg>
);
