"use client";

import {extendVariants, Input} from "@nextui-org/react";

// Responsive Reference:https://github.com/nextui-org/nextui/discussions/2047

export const NeoTextBox = extendVariants(Input, {
  variants: {
    variant: {
      bordered: {
        inputWrapper: [
          //"bg-neo-white",
          "border-2",
          "shadow-none",
          //"focus-within:bg-neo-white",
          "data-[hover=true]:border-gray-600",
          //"data-[hover=true]:bg-neo-white",
          "group-data-[focus=true]:border-gray-600"
        ],
        input: "text-black dark:text-gray-300"
      },
      size: {
        xs: {
          inputWrapper: "h-unit-6 min-h-unit-6 px-1",
          input: "text-tiny"
        },
        md: {
          inputWrapper: "h-unit-16 min-h-unit-16",
          input: "text-medium"
        },
        lg: {
          inputWrapper: "h-unit-16 min-h-unit-16",
          input: "text-medium"
        }
      },
      radius: {
        xs: {
          inputWrapper: "rounded-small"
        },
        sm: {
          inputWrapper: "rounded-small"
        },
        md: {
          inputWrapper: "rounded-small"
        },
        lg: {
          inputWrapper: "rounded-medium"
        }
      },
      textSize: {
        base: {
          input: "text-base"
        }
      }
    }
  },
  defaultVariants: {
    textSize: "base",
    variant: "bordered",
    labelPlacement: "outside",
    radius: "lg",
    size: "lg"
  }
});
