interface NeoDescriptionProps {
  message: string;
}

export const NeoDescription: React.FC<NeoDescriptionProps> = ({message}) => {
  return (
    <p className="mb-3 text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
      {message}
    </p>
  );
};
