export const LogoutIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M12.4996 28.7491C12.4996 29.0806 12.3679 29.3985 12.1335 29.6329C11.8991 29.8673 11.5811 29.999 11.2496 29.999H2.49992C1.8369 29.999 1.20103 29.7356 0.732209 29.2668C0.263383 28.798 0 28.1621 0 27.4991V2.49992C0 1.8369 0.263383 1.20103 0.732209 0.732209C1.20103 0.263383 1.8369 0 2.49992 0H11.2496C11.5811 0 11.8991 0.131692 12.1335 0.366105C12.3679 0.600518 12.4996 0.918449 12.4996 1.24996C12.4996 1.58147 12.3679 1.8994 12.1335 2.13381C11.8991 2.36823 11.5811 2.49992 11.2496 2.49992H2.49992V27.4991H11.2496C11.5811 27.4991 11.8991 27.6308 12.1335 27.8652C12.3679 28.0996 12.4996 28.4175 12.4996 28.7491ZM29.6334 14.1152L23.3836 7.86537C23.1491 7.63082 22.831 7.49906 22.4993 7.49906C22.1676 7.49906 21.8495 7.63082 21.6149 7.86537C21.3804 8.09991 21.2486 8.41802 21.2486 8.74971C21.2486 9.08141 21.3804 9.39952 21.6149 9.63406L25.732 13.7495H11.2496C10.9181 13.7495 10.6002 13.8812 10.3658 14.1157C10.1314 14.3501 9.99967 14.668 9.99967 14.9995C9.99967 15.331 10.1314 15.649 10.3658 15.8834C10.6002 16.1178 10.9181 16.2495 11.2496 16.2495H25.732L21.6149 20.365C21.3804 20.5995 21.2486 20.9176 21.2486 21.2493C21.2486 21.581 21.3804 21.8991 21.6149 22.1337C21.8495 22.3682 22.1676 22.5 22.4993 22.5C22.831 22.5 23.1491 22.3682 23.3836 22.1337L29.6334 15.8839C29.7496 15.7678 29.8418 15.6299 29.9047 15.4782C29.9676 15.3264 30 15.1638 30 14.9995C30 14.8352 29.9676 14.6726 29.9047 14.5208C29.8418 14.3691 29.7496 14.2312 29.6334 14.1152Z" />
  </svg>
);
