"use client";
import {Checkbox, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoCheckbox = extendVariants(Checkbox, {
  variants: {
    color: {
      default: {
        wrapper: "after:bg-neo-green after:text-neo-white text-neo-white"
      }
    },
    radius: {
      none: {
        wrapper: "rounded-none before:rounded-none after:rounded-none"
      },
      sm: {
        wrapper: [
          "rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "before:rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "after:rounded-[calc(theme(borderRadius.medium)*0.4)]"
        ]
      },
      md: {
        wrapper: [
          "rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "before:rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "after:rounded-[calc(theme(borderRadius.medium)*0.4)]"
        ]
      },
      lg: {
        wrapper: [
          "rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "before:rounded-[calc(theme(borderRadius.medium)*0.4)]",
          "after:rounded-[calc(theme(borderRadius.medium)*0.4)]"
        ]
      }
    },
    lineThrough: {
      true: {
        label: [
          "inline-flex",
          "items-center",
          "justify-center",
          "before:content-['']",
          "before:absolute",
          "before:bg-neo-white",
          "before:w-0",
          "before:h-0.8",
          "group-data-[selected=true]:opacity-60",
          "group-data-[selected=true]:before:w-full"
        ]
      }
    }
  },
  defaultVariants: {
    color: "default",
    size: "md",
    lineThrough: false
  }
});
