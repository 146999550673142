"use client";
import {ButtonGroup, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoButtonGroup = extendVariants(ButtonGroup, {
  variants: {
    color: {
      yellow: "text-neo-black bg-neo-yellow",
      black: "bg-neo-black text-neo-white",
      white: "bg-neo-white text-neo-black border-neo-black-400 border-2",
      red: "bg-neo-red text-neo-white",
      green: "bg-neo-green text-neo-white"
    },
    isDisabled: {
      true: "bg-[#eaeaea] text-[#000] opacity-50 cursor-not-allowed"
    },
    size: {
      xs: "px-unit-2 min-w-unit-12 h-unit-6 text-tiny gap-unit-1 rounded-small",
      md: "min-w-unit-18 h-unit-10 text-small rounded-medium",
      lg: "px-unit-7 min-w-unit-28 h-unit-12 text-small gap-unit-4 rounded-medium",
      xl: "px-unit-8 min-w-unit-28 h-unit-14 text-large gap-unit-4 rounded-medium"
    }
  },
  defaultVariants: {
    color: "green",
    size: "md",
    radius: "md"
  }
});
