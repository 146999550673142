export const DriversIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path
      d="M27.083 21.0094L27.083 21.0095C25.1001 24.8315 22.0116 27.1533 17.7514 27.7928C12.4751 28.5849 7.3424 25.8658 4.96452 21.1196L4.9645 21.1195C4.79745 20.7863 4.71946 20.4736 4.73397 20.1795C4.74836 19.8878 4.85434 19.5995 5.08007 19.3132C5.31395 19.0166 5.57889 18.838 5.86789 18.7532C6.15858 18.6679 6.48876 18.6732 6.85796 18.7702C7.57402 18.9586 8.29058 19.1442 9.02351 19.3339C9.47251 19.4502 9.92765 19.568 10.3926 19.6891L10.7513 19.7825L10.6324 19.4314L8.42968 12.9234L8.37441 12.7601L8.20474 12.7907C8.03257 12.8218 7.86157 12.8524 7.69146 12.8828C7.2572 12.9604 6.82871 13.037 6.40133 13.118L6.40126 13.118C5.75814 13.24 5.22859 13.0704 4.82046 12.579C4.40793 12.0823 4.31882 11.522 4.57629 10.9357L4.57632 10.9356C6.37428 6.83628 9.4376 4.27536 13.8397 3.46111L13.8397 3.4611C17.6363 2.75859 21.0489 3.7418 24.0171 6.21336C25.5553 7.49426 26.6878 9.09794 27.4364 10.9643C27.6724 11.5531 27.5813 12.0997 27.1773 12.5849C26.7767 13.066 26.2528 13.2389 25.6223 13.1238L25.6221 13.1238C25.1526 13.0386 24.6834 12.9522 24.1966 12.8627C24.0656 12.8386 23.9334 12.8142 23.7996 12.7896L23.6294 12.7584L23.574 12.9222C23.3102 13.7018 23.0464 14.4804 22.7818 15.2612C22.3152 16.6382 21.8465 18.0218 21.3718 19.4279L21.2539 19.7771L21.6109 19.6857C21.866 19.6203 22.1121 19.5574 22.3529 19.4959C22.7163 19.4031 23.0674 19.3134 23.4183 19.2232L23.4183 19.2232C23.6167 19.1721 23.8142 19.1204 24.0113 19.0689C24.4296 18.9594 24.8461 18.8504 25.2659 18.7487C25.6118 18.665 25.9194 18.6754 26.1901 18.7684C26.4607 18.8613 26.7079 19.0412 26.9257 19.3182C27.1356 19.5858 27.2487 19.8607 27.2746 20.1374C27.3005 20.4141 27.2405 20.706 27.083 21.0094ZM16.033 0.391971C7.63292 0.384582 0.810481 7.16977 0.800012 15.5443C0.789545 23.959 7.5766 30.784 15.9664 30.792C24.3783 30.8006 31.1969 24.0053 31.2 15.6097C31.2031 7.21488 24.4116 0.398745 16.033 0.391971Z"
      stroke="inherit"
      strokeWidth="0.4"
    />
    <path d="M21.6179 16.1942C21.1318 17.3774 20.4798 18.4711 19.7433 19.5163C19.412 19.9964 18.908 20.3299 18.3366 20.4472C16.8019 20.7994 15.2075 20.7994 13.6728 20.4472C13.1211 20.3221 12.652 20.0668 12.319 19.5965C11.2027 18.0166 10.2377 16.3614 9.83852 14.4418C9.75273 13.9851 9.72293 13.5196 9.7498 13.0557C9.77532 12.431 10.081 12.1266 10.6898 12.0142C12.4472 11.6885 14.2234 11.5888 16.1205 11.5815C17.7697 11.5931 19.5233 11.6824 21.2637 12.0069C21.9394 12.1333 22.2128 12.4383 22.2608 13.1323C22.335 14.2145 22.019 15.2171 21.6179 16.1942Z" />
  </svg>
);
