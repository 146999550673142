export const DashboardIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path
      d="M14.8789 1.36318L14.6818 1.37169L14.677 1.3766C11.2118 1.45891 7.76799 2.88469 5.1319 5.67475C-0.293733 11.4172 -0.287106 20.7281 5.14185 26.4743C5.62157 26.982 6.13042 27.4716 6.67768 27.9031C6.85182 28.0429 7.07474 28.1113 7.30131 28.0874C7.52668 28.0635 7.72859 27.9512 7.86995 27.7824C7.90223 27.7446 7.93269 27.7087 7.96183 27.6743C8.04843 27.5721 8.12343 27.4835 8.20012 27.4023C11.9632 23.4195 18.042 23.425 21.8101 27.4129C21.9232 27.5326 22.0317 27.6555 22.1424 27.7848C22.2831 27.9492 22.4818 28.0585 22.7031 28.0824C22.9247 28.1063 23.1434 28.0418 23.3165 27.9078L23.3165 27.9078L23.3196 27.9054C23.8608 27.4796 24.378 27.0037 24.8683 26.4847C30.2939 20.7423 30.2873 11.4313 24.8584 5.68523L24.5136 6.01093L24.8584 5.68522C22.1428 2.81116 18.5832 1.36728 15.0116 1.36264C14.9933 1.36196 14.975 1.36188 14.9566 1.36239C14.9477 1.36227 14.9388 1.36229 14.9299 1.36245C14.9115 1.36216 14.8943 1.36252 14.8789 1.36318ZM16.2124 12.671L19.8282 9.71165L19.9727 9.60333C20.0398 9.55301 20.0853 9.51984 20.1201 9.49659C20.0938 9.55063 20.0552 9.615 19.9945 9.70571L19.9942 9.70607L17.1348 13.9881L17.0149 14.1676L17.0634 14.378C17.2398 15.1432 17.0472 15.9691 16.4997 16.5485C15.673 17.4221 14.3371 17.4235 13.5104 16.5485L13.147 16.8919L13.5104 16.5485C12.6681 15.657 12.6688 14.192 13.5104 13.3013L13.5105 13.3012C13.7576 13.0396 14.1283 12.8544 14.555 12.7621C14.9808 12.6701 15.4223 12.6798 15.7728 12.7687L16.0172 12.8307L16.2124 12.671Z"
      stroke="black"
    />
  </svg>
);
