"use client";
import {Tabs, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoTabs = extendVariants(Tabs, {
  variants: {
    variant: {
      underlined: {
        tabContent: "text-base group-data-[selected=true]:text-neo-green",
        tab: "bg-gray-200 h-14 data-[selected=true]:bg-white",
        tabList: "bg-gray-100 gap-0 p-0 rounded",
        cursor: "w-full bg-neo-green top-0"
      }
    }
  },
  defaultVariants: {
    variant: "solid",
    color: "warning"
  }
});
