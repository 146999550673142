export const PinIcon = ({
  size = 30,
  width,
  height,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}
    {...props}
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1
      }}
    >
      <path
        d="M45 90c-12.811 0-25.78-4.03-25.78-11.732S32.189 66.537 45 66.537c12.811 0 25.781 4.03 25.781 11.732S57.812 90 45 90zm0-18.23c-13.294 0-20.547 4.292-20.547 6.498 0 2.206 7.252 6.498 20.547 6.498 13.295 0 20.547-4.292 20.547-6.498 0-2.205-7.252-6.498-20.547-6.498z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#ffbc27",
          fillRule: "nonzero",
          opacity: 1
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      />
      <path
        d="M45 0C30.604 0 18.934 11.67 18.934 26.066c0 14.396 13.032 36.644 26.066 52.587C58.033 62.71 71.066 40.462 71.066 26.066S59.396 0 45 0zm0 37.469c-6.516 0-11.798-5.282-11.798-11.798S38.484 13.873 45 13.873s11.798 5.282 11.798 11.798S51.516 37.469 45 37.469z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#dc2028",
          fillRule: "nonzero",
          opacity: 1
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      />
    </g>
  </svg>
);
