export const WarningIcon = ({
  size = 104,
  width = 102,
  height = 91,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC727"
      d="M100.057 73.984 60.628 5.51a11.163 11.163 0 0 0-19.252 0L1.948 73.984a10.6 10.6 0 0 0 0 10.694 10.978 10.978 0 0 0 9.626 5.496H90.43a10.979 10.979 0 0 0 9.617-5.496 10.608 10.608 0 0 0 .009-10.694Zm-6.254 7.083a3.833 3.833 0 0 1-3.372 1.893H11.574A3.833 3.833 0 0 1 8.2 81.067a3.422 3.422 0 0 1 0-3.481L47.63 9.112a3.945 3.945 0 0 1 6.763 0L93.82 77.586a3.422 3.422 0 0 1-.018 3.48ZM47.395 54.105V36.07a3.607 3.607 0 1 1 7.214 0v18.035a3.607 3.607 0 0 1-7.214 0Zm9.018 16.231a5.41 5.41 0 1 1-10.822 0 5.41 5.41 0 0 1 10.822 0Z"
    />
  </svg>
);
