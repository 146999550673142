"use client";
import {extendVariants, Switch} from "@nextui-org/react";

// Responsive Reference:https://github.com/nextui-org/nextui/discussions/2047

export const NeoSwitch = extendVariants(Switch, {
  defaultVariants: {
    color: "success"
  }
});
