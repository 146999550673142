"use client";
import {Progress, extendVariants} from "@nextui-org/react";

export const NeoProgress = extendVariants(Progress, {
  variants: {
    color: {
      success: {
        indicator: "!bg-neo-green",
        base: "!gap-1",
        track: "bg-neo-gray-100 !h-2",
        value: "text-xs",
        labelWrapper: "!justify-end"
      }
    }
  },
  defaultVariants: {
    value: "0",
    showValueLabel: "true",
    color: "success"
  }
});
