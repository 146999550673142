"use client";

import {Label} from "flowbite-react";
import React, {useState} from "react";
import DatePicker, {
  CalendarProps,
  DateObject,
  DatePickerProps
} from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
interface NeoDatePickerProps
  extends Omit<CalendarProps, "onChange">,
    Omit<DatePickerProps, "onChange"> {
  type?: "input" | "icon" | undefined;
  label?: string;
  errorMessage?: any;
  className?: string;
  showTime?: boolean;
  handleDateChange?: (date: DateRange | Date | Date[] | null) => void;
}
export const NeoDatePicker: React.FC<NeoDatePickerProps> = ({
  type = "input",
  label,
  errorMessage,
  showTime,
  handleDateChange,
  ...props
}) => {
  const [selectedRangeDates, setSelectedRangeDates] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 59))
  ]);
  const onDateChange = (e: DateObject | DateObject[] | null) => {
    if (e instanceof DateObject) {
      handleDateChange?.(e?.toDate());
    } else if (Array.isArray(e) && e.length > 0 && e[0] instanceof DateObject) {
      if (props.range) {
        const from = e[0]?.toDate();
        const to = e[1]?.toDate();
        if (from && to) {
          //from.setHours(0, 0, 0, 0);
          const result: DateRange = {from, to};
          handleDateChange?.(result);
          // setSelectedRangeDates([from, to]);
        }
      } else {
        const result = e.map(x => x?.toDate());
        handleDateChange?.(result);
      }
    }
  };

  return (
    <>
      {(() => {
        switch (type) {
          case "input": {
            return (
              <div className="grid w-full grid-cols-1">
                {label && (
                  <Label
                    className={`mb-1 block text-base font-normal ${errorMessage ? "text-red-500" : ""}`}
                    value={`${label}`}
                  />
                )}
                <DatePicker
                  {...props}
                  format={showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
                  onChange={onDateChange}
                  containerClassName="w-full [&_svg]:!right-3.5"
                  render={
                    <InputIcon
                      //placeholder={props.placeholder}
                      className={`border-neo-gray-100 h-unit-12 min-h-unit-12 w-full
                                            rounded-medium border-2 bg-neo-white px-3 shadow-none
                                            outline-none hover:border-neo-gray ${errorMessage && "!border-red-500"}`}
                    />
                  }
                  plugins={
                    showTime
                      ? [<TimePicker key="time-picker1" position="bottom" />]
                      : []
                  }
                />
                {errorMessage && (
                  <span className="p-1 text-tiny text-danger">
                    {errorMessage}
                  </span>
                )}
              </div>
            );
          }
          case "icon": {
            return (
              <div>
                <DatePicker
                  {...props}
                  //value={selectedRangeDates}
                  format={showTime ? "MM-DD-YYYY HH:mm:ss" : "MM-DD-YYYY"}
                  onChange={onDateChange}
                  render={<Icon className="text-neo-gray" />}
                  plugins={
                    showTime
                      ? [
                          <TimePicker key="time-picker2" position="bottom" />,
                          <DatePanel key="time-picker3" markFocused />
                        ]
                      : []
                  }
                >
                  {/* <div>
                  <NeoButton
                    className="mx-5 p-3"
                    type="submit"
                    color="black"
                    onClick={() => showTime = false}
                  >
                    Next
                  </NeoButton>
                </div> */}
                </DatePicker>
              </div>
            );
          }
          default: {
            return <span className="text-neo-red">Error</span>;
          }
        }
      })()}
    </>
  );
};
