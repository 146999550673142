"use client";
import {Card, extendVariants} from "@nextui-org/react";

//  Reference:https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components

export const NeoCard = extendVariants(Card, {
  defaultVariants: {
    radius: "sm",
    shadow: "sm"
  }
});
