export const MenuIcon = ({
  size = 30,
  width,
  height,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)" opacity={0.6}>
      <path
        fill="#000"
        stroke="#000"
        d="m9.672 15.251.49-.097-.49.097a1.476 1.476 0 1 0 2.896-.576 1.476 1.476 0 0 0-2.896.576Zm.22-14.39a1.477 1.477 0 1 0 2.456 1.641A1.477 1.477 0 0 0 9.893.862Zm1.228 5.985a1.477 1.477 0 1 0 0 2.953 1.477 1.477 0 0 0 0-2.953Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.738 0H21v18.375H.738z" />
      </clipPath>
    </defs>
  </svg>
);
