import {Label} from "flowbite-react";

export type FieldType = "text" | "date" | "number" | "phone" | "array";

interface NeoSubHeaderProps {
  label: string;
  value: string | string[];
  type?: FieldType;
}

export const NeoLabel: React.FC<NeoSubHeaderProps> = ({label, value, type}) => {
  if (type === "date") {
    return (
      <>
        <Label className="mb-2 block text-gray-500" value={label} />
        <Label className="mb-2 block font-semibold" value={value as string} />
      </>
    );
  }

  if (type === "phone") {
    return (
      <>
        <Label className="mb-2 block text-gray-500" value={label} />
        <Label
          className="mb-2 block font-semibold text-black"
          value={value as string}
        />
      </>
    );
  }

  return (
    <>
      <Label className="mb-2 block text-gray-500" value={label} />
      <Label className="mb-2 block font-semibold" value={value as string} />
    </>
  );
};
