"use client";
import {Slider, extendVariants} from "@nextui-org/react";

export const NeoSlider = extendVariants(Slider, {
  variants: {
    color: {
      warning: {
        filler: "bg-neo-yellow",
        track: "bg-neo-gray",
        thumb: [
          "transition-size",
          "bg-neo-yellow",
          "data-[dragging=true]:shadow-lg data-[dragging=true]:shadow-black/20",
          "data-[dragging=true]:w-7 data-[dragging=true]:h-7 data-[dragging=true]:after:h-6 data-[dragging=true]:after:w-6"
        ]
      }
    }
  }
});
